(function() {
    'use strict';

    angular.module('aerosApp').
    factory('TestLimitResource', TestLimitResource).
    //adapters for Presets interface
    factory('OLTSPresetService', PresetServiceAdapter('OLTS')).
    factory('CertificationPresetService', PresetServiceAdapter('Certification')).
    factory('InspectionPresetService', PresetServiceAdapter('Inspection'));

    TestLimitResource.$inject = ['$resource', '$stateParams', 'CommonService'];
    function TestLimitResource ($resource, $stateParams, CommonService) {
        var baseUrl = CommonService.baseUrl + '/organization/:orgId';

        return function (params, methods) {
            var resource = $resource(baseUrl,
                {
                    orgId     : '@orgId',
                    key       : '@key'
                }, _.defaultsDeep(methods, {
                    query     : {
                        url: baseUrl + '/alltestLimits',
                        isArray: false,
                        interceptor: {response: intercept}
                    },
                    create    : {
                        url: baseUrl + '/testLimit',
                        method: 'POST',
                        transformRequest: transformCreateRequest
                    },
                    delete    : {
                        url: baseUrl + '/testLimit/:key',
                        params: { key: params.key },
                        method: 'DELETE'
                    }
                }));

            var TestLimitPrototype = TestLimitModelPrototype(resource);
            return resource;

            function transformCreateRequest (data) {
                var testLimit = _.defaults({fiberType: data.fiberType.id}, data);
                return angular.toJson({testLimit: testLimit});
            }

            function intercept (response) {
                return response.data.limits
                    .filter(filterOutObsolete)
                    .map(addToolProps)
                    .map(modelize);
            }

            function modelize (item) {
                return _.extend(Object.create(TestLimitPrototype), item);
            }

            function filterOutObsolete(limit) {
                return !limit.obsolete;
            }

            function addToolProps (item) {
                var maxLoss = item.lossThresholds && getMaxLoss(item.lossThresholds);
                return _.defaults(item, {
                    toolType: params.toolType,
                    maxLoss: maxLoss,
                    active: !item.archived
                });
            }

            function getMaxLoss (lossThresholds) {
                return _.chain(lossThresholds).
                mapValues(function(val, key) {
                    return '@ '+key+' nm: ' + val + 'dB';
                }).values().join(', ').value();
            }

            function TestLimitModelPrototype (resource) {
                return {
                    deactivate: deactivate,
                    denyView: true
                };

                function deactivate () {
                    return resource.
                    delete({ orgId: $stateParams.id, key: this.key}).$promise;
                }
            }

        }
    }

    function PresetServiceAdapter (toolType) {
        return ['TestLimitResource', adapter]; // DI
        function adapter (TestLimitResource) {
            var resource = TestLimitResource({toolType: toolType});
            return {
                fetch: fetch,
                create: create
            };

            function fetch (orgId) {
                return resource.query({orgId: orgId}).
                $promise.then(function(limits) {
                    var obj = {};
                    obj[toolType] = {testLimits: limits};
                    return obj;
                });
            }

            function create (orgId, data) {
                return resource.create({orgId: orgId}, data).
                    $promise;
            }
        }
    }

}());
